@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800");
:root {
  --primary-color: #30d180;
  --success-color: #9df99d;
  --warning-color: #e1ec86;
  --pending-color: #bfbeef;
  --error-color: #efcaca;
  --text-color: #333;
  --white-color: #fff;
  --background-color: #fff;
  --shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.3);
  --button-color: rgb(87, 87, 87);
  --button-background: linear-gradient(
    90deg,
    hsla(173, 67%, 76%, 1) 0%,
    hsla(115, 94%, 67%, 1) 100%
  );
  --l-width: 1248px;
  --s-width: 576px;
}

html {
  font-size: 62.5%;
  font-weight: 400;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
  font-family: "Inter";
}
body {
  background-image: radial-gradient(
    circle,
    #ffffff,
    #fdfeff,
    #fbfdff,
    #f7fcff,
    #f3fcff,
    #eefbff,
    #e8fbff,
    #e3faff,
    #dcf8ff,
    #d5f7ff,
    #cdf5ff,
    #c6f3ff
  );
}

a[href] {
  text-decoration: none;
}
ul,
p {
  list-style: none;
  margin: 0;
  padding: 0;
}
input {
  box-sizing: border-box;
  width: 100%;
  &:focus {
    outline: none;
  }
}
button {
  border: none;
  background: none;
}

//Scroll-Bar
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px grey;
  border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(161, 161, 161);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background: rgb(131, 131, 131);
}
.slick-slide > div {
  padding: 0 5px;
}

.ant-switch-checked {
  background: linear-gradient(
    90deg,
    rgb(128, 250, 156) 16%,
    rgb(144, 249, 167) 21%,
    rgb(48, 245, 31) 100%
  ) !important;
}

@media only screen and (max-width: 1024px) {
  #root {
    display: none;
  }
}
