.wrapper {
  max-width: var(--l-width);
  margin: 0 auto;
  margin-top: -10rem;
  z-index: 9;

  .nav {
    .nav-left {
      padding-top: 20rem;
      .slogan {
        > p {
          padding: 0.4rem 0;
          font-size: 4rem;
          font-weight: 800;
          color: #000c4d;
          > b {
            font-size: 4rem;
            font-weight: 800;
            color: #fff;
            text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
              1px 1px 0 #000;
          }
        }
      }
      .sub-slogan {
        padding: 3rem 0;
        > p {
          padding-bottom: 0.4rem;
          font-size: 2rem;
          font-weight: 400;
          color: #7c7c7c;
        }
      }
      .partner {
        > p {
          font-size: 2.2rem;
          font-weight: 500;
          margin-bottom: 2rem;
        }
        .list-partner {
          height: 6rem;
          display: flex;
          > li {
            width: 25%;
            padding-right: 3rem;
            > img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }
    .nav-right {
      .banner {
        height: 72rem;
        > img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-end-start-radius: 14rem;
        }
      }
    }
  }

  .list-option {
    margin-top: 1rem;
    overflow-y: auto;
    max-height: 60rem;
  }

  .news-box {
    .sub-title {
      display: flex;
      align-items: center;
      > p {
        border-top: 1px solid #ffa318;
        width: 3rem;
        height: 4px;
      }
      > span {
        padding-left: 2rem;
        color: #ffa318;
        font-weight: 500;
      }
    }
    .news-action {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 6rem;
      .title {
        font-size: 3rem;
        font-weight: 700;
        color: #000c4d;
      }
      .news-button {
        display: flex;
        .button-item {
          display: flex;
          font-size: 2rem;
          border: 1px solid #ccc;
          margin-right: 3rem;
          padding: 1.4rem 2.4rem;
          border-radius: 3rem;
          align-items: center;
          color: #000;
          transition: 0.2s;
          cursor: pointer;
          &:hover {
            background: #a2ffc3;
          }
        }
        .active {
          color: #00a439;
          background: #a2ffc3;
          border-color: #00a439;
        }
      }
      .new-slide {
        display: flex;
        .btn {
          visibility: hidden;
          padding: 3rem 2.2rem;
        }
        .btn:first-child {
          background: #dcdcdc;
          color: #4f4f4f;
        }
        .btn:last-child {
          margin-left: 2rem;
          background: #00a439;
          color: #fff;
        }
      }
    }

    .new-items {
      cursor: pointer;
      padding-right: 4rem;

      &:hover {
        .news-image > img {
          filter: grayscale(50%);
          transform: scale(1.2);
        }
      }
      &:hover .news-image .btn-detail {
        display: flex;
        animation: up 0.3s ease-in forwards;
      }
      .news-image {
        position: relative;
        width: 100%;
        height: 44rem;
        border-radius: 4rem;
        overflow: hidden;
        > img {
          width: 100%;
          height: 100%;
          object-fit: fill;
          transition: 0.2s linear;

          border-radius: 4rem;
        }
        .news-tag {
          display: flex;
          align-items: center;
          position: absolute;
          left: 3rem;
          bottom: 2.4rem;
          background: #ffc1c1;
          color: #ff2323;
          font-size: 1.4rem;
          font-weight: 500;
          padding: 1rem 2rem;
          border-radius: 2rem;
          > svg {
            margin-right: 1rem;
          }
        }
        .tag-vip {
          position: absolute;
          top: 1rem;
          left: 2rem;
          width: 6rem;
          height: 6rem;
          > img {
            width: 100%;
            height: 100%;
          }
        }
        .btn-detail {
          display: none;
          position: absolute;
          bottom: 0%;
          left: 30%;
          color: #000;
          padding: 2.4rem 3rem;
          background: #fff;
          z-index: 99;
        }
        .btns {
          position: absolute;
          top: 2rem;
          right: 2.4rem;
          color: rgb(255, 157, 157);
          transition: 0.2s;
          > svg {
            font-size: 3.6rem;
          }
          &:hover {
            transform: scale(1.2);
            color: rgb(255, 67, 67);
          }
        }
      }
      .news-name {
        min-height: 6.2rem;
        margin-top: 2rem;
        > span {
          display: flex;
          align-items: center;
          > p {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            color: #185918;
            font-size: 2.6rem;
            font-weight: 700;
            overflow: hidden;
          }
          > svg {
            font-size: 2rem;
            color: #d8e300;
          }
        }
      }
      .news-price {
        margin-top: 1rem;
        > p {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          > b {
            font-size: 2rem;
            font-weight: 500;
          }
          > small {
            font-size: 1.4rem;
            color: #989898;
            display: flex;
            align-items: center;
            > svg {
              margin-right: 0.4rem;
            }
          }
        }
      }

      .news-actor {
        margin-top: 2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .actor-avatar {
          min-width: 6rem;
          height: 6rem;
          border-radius: 50%;
          overflow: hidden;
          > img {
            width: 100%;
            height: 100%;
          }
        }
        .actor-info {
          margin-left: 2rem;
          .actor-name {
            display: flex;
            align-items: center;
            padding-bottom: 1rem;
            font-weight: 600;
            font-size: 1.8rem;
            > svg {
              margin-left: 0.4rem;
              color: #009c05;
            }
          }
          .actor-address {
            color: #575757;
            font-size: 1.5rem;
            font-weight: 500;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
        }
      }
    }
    .btn {
      position: absolute;
      top: -12rem;
      padding: 3rem 2.2rem;
      z-index: 99;
    }

    .btn-prev {
      background: #dcdcdc;
      color: #4f4f4f;
      right: 9.4rem;
    }
    .btn-next {
      right: 0;
      background: #00a439;
      color: #fff;
    }
  }

  .sale-ready {
    margin-top: 10rem;
    .sale-info {
      .sub-title {
        display: flex;
        align-items: center;
        > p {
          border-top: 1px solid #ffa318;
          width: 3rem;
          height: 4px;
        }
        > span {
          padding-left: 2rem;
          color: #ffa318;
          font-weight: 500;
        }
      }
      .title {
        padding: 2rem 0;
        font-size: 3rem;
        font-weight: 700;
        line-height: 4.4rem;
        color: #000c4d;
      }
      .description {
        color: #7c7c7c;
        line-height: 3rem;
      }
      .house-detail {
        padding: 2rem 0;
        border-bottom: 2px solid #dcdcdc;
        > h2 {
          padding-bottom: 2rem;
          font-size: 2rem;
        }
        .info {
          width: 100%;
          padding: 0 0 2rem 0;
          display: flex;
          > span {
            text-align: left;
            width: 50%;
            font-weight: 600;
            color: #4f4f4f;
            display: flex;
            align-items: center;
            > svg {
              margin-right: 2rem;
              font-size: 3.4rem;
            }
          }
        }
      }

      .contact {
        margin-top: 4rem;
        display: flex;
        align-items: center;
        .actor-info {
          width: 60%;
          display: flex;
          align-items: center;
          .actor-image {
            width: 6rem;
            height: 6rem;
            > img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }
          .detail {
            padding: 0 3rem 0 2rem;
            .actor-name {
              font-weight: 600;
              padding-bottom: 1rem;
              font-size: 2rem;
              > svg {
                color: #009c05;
              }
            }
            .actor-address {
              font-weight: 600;
              color: #616161;
            }
          }
        }
        .btn-contact {
          margin-left: 4rem;
          font-weight: 700;
          padding: 3rem 2.2rem 3rem 2rem;
          > svg {
            margin-right: 2rem;
          }
        }
      }
    }
    .sale-image {
      padding-left: 8rem;
      .main-image {
        height: 46rem;
        position: relative;
        > img {
          width: 100%;
          height: 100%;
          object-fit: fill;
          border-radius: 8px;
        }
      }
      .sub-image1 {
        position: absolute;
        height: 20rem;
        width: 30rem;
        bottom: -1rem;
        left: 5rem;
        > img {
          width: 100%;
          height: 100%;
          object-fit: fill;
          border-radius: 8px;
        }
      }
      .sub-image2 {
        padding-left: 1rem;
        position: absolute;
        height: 10rem;
        width: 26rem;
        display: flex;
        justify-content: space-between;
        bottom: -1rem;
        right: 3rem;
        > img {
          width: 50%;
          height: 100%;
          object-fit: fill;
          margin: 0 0rem 0 1rem;
          border-radius: 8px;
        }
      }
    }
  }

  .review {
    margin-top: 10rem;
    .sub-title {
      display: flex;
      flex-direction: column;
      align-items: center;
      > p {
        border-top: 1px solid #ffa318;
        width: 3rem;
        height: 4px;
      }
      > span {
        padding-top: 1rem;
        color: #ffa318;
        font-weight: 500;
      }
    }
    .title {
      padding: 2rem 0;
      font-size: 3rem;
      font-weight: 700;
      color: #000c4d;
      text-align: center;
    }
    .news-review {
      margin: 0 4rem;
      .news-review-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        padding-bottom: 16rem;
        .main-image {
          width: 100%;
          height: 40rem;
          > img {
            border-radius: 8px;
            width: 100%;
            height: 100%;
            opacity: 0.8;
          }
        }
        .review {
          position: absolute;
          padding: 2rem 6rem 2rem 4rem;
          background: #fff;
          border-radius: 16px;
          width: 80%;
          bottom: 5rem;
          .review-title {
            font-weight: 700;
            color: #000c4d;
            font-size: 2rem;
            padding-top: 1rem;
          }
          .review-content {
            color: #7c7c7c;
            padding: 2rem 0;
            line-height: 3rem;
          }
          .review-user {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            .user-info {
              display: flex;
              .user-image {
                width: 5rem;
                height: 5rem;
                margin-right: 2rem;
                > img {
                  width: 100%;
                  height: 100%;
                  object-fit: fill;
                  border-radius: 50%;
                }
              }
              .user-detail {
                .user-name {
                  font-weight: 600;
                  padding-bottom: 1rem;
                }
                .user-address {
                  font-size: 1.4rem;
                  color: #616161;
                  font-weight: 600;
                }
              }
            }
            .vote {
              > span {
                display: flex;
                align-items: center;
                color: #000c4d;
                font-weight: 600;
                font-size: 2.2rem;
                > svg {
                  font-size: 3.6rem;
                  margin-right: 1.4rem;
                  color: #ffa318;
                }
              }
            }
          }
        }
      }
    }
  }
}

@keyframes up {
  from {
    opacity: 0;
    bottom: 0;
  }
  to {
    opacity: 1;
    bottom: 40%;
  }
}
