.favorite {
  max-width: var(--l-width);
  margin: 0 auto;
  margin-top: -8rem;

  .title {
    font-weight: 800;
    font-size: 2.8rem;
    margin: 1.4rem 0;
  }
  .list-favorite {
    border: 1px solid #ccc;
    margin: 3rem 0;
    border-radius: 4px;
    padding: 2rem 1.4rem;
  }
  .empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    > img {
      width: 40%;
      height: 30rem;
      object-fit: contain;
    }
    > h1 {
      padding-top: 2rem;
      font-size: 4rem;
    }
  }
  .sidebar {
    .category {
      border: 1px solid #ccc;
      margin: 3rem 0;
      border-radius: 4px;
      padding: 2rem 1.4rem;
      > h1 {
        font-size: 2rem;
        padding-bottom: 2rem;
      }
      .main {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px dashed #c0c0c0;
        .cate-items {
          display: flex;
          align-items: center;
          cursor: pointer;
          padding: 1rem 0;

          > p {
            font-size: 2rem;
            transition: 0.2s ease-in;
            color: #8f8f8f;
          }
          > h2 {
            > a {
              font-size: 1.4rem;
              font-weight: 400;
              color: black;
              &:hover {
                color: #16c784;
              }
            }
          }
          &:hover > p {
            transform: rotate(90deg);
          }
        }
        > p {
          margin-left: 5px;
          font-size: 1.4rem;
          color: #8a8d91;
        }
        &:last-child {
          border: none;
        }
      }
      .sub {
        margin-left: 1.5rem;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 1rem 0;
        border-bottom: 1px dashed #c0c0c0;
        > p {
          font-size: 2rem;
          transition: 0.2s ease-in;
          color: #8f8f8f;
        }
        > h2 {
          > a {
            color: black;
            font-size: 1.4rem;
            font-weight: 400;
            &:hover {
              color: #16c784;
            }
          }
        }
        &:hover > p {
          transform: rotate(90deg);
        }
      }
    }
    .care {
      width: 100% !important;
    }
  }
}
