.wrapper {
  > h1 {
    font-size: 3rem;
    margin-top: 1rem;
  }
  .manage-rate {
    display: flex;
    align-items: center;
    margin-top: 2rem;
    > table,
    th,
    td {
      border: 1px solid #ccc;
      border-collapse: collapse;
    }
    > table {
      width: 90%;
      margin-right: 1rem;
      > tr {
        height: 4rem;
      }
      .btn{
        display: flex;
        margin: 2rem;
        .btn-delete {
          display: flex;
          align-items: center;
          margin-bottom: 1rem;
          margin-right: 1rem;
          padding: 0.6rem;
          border-radius: 1rem;
          background-color: #dc3545;
          color: #fff;
          &:hover {
            background-color: #b82e3b;
            cursor: pointer;
          }
          > a {
            margin-left: 0.4rem;
            color: #fff;
          }
        }
        .btn-reply {
            width: 10rem;
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
            padding: 0.6rem;
            border-radius: 1rem;
            background-color: #00aeff;
            color: #fff;
            &:hover {
              background-color: #42c3ff;
              cursor: pointer;
            }
            > a {
              color: #fff;
              margin-left: 0.4rem;
            }
        }
      }
    }
    
    
  }
}
.menu-status {
  .container {
    height: 30rem;
    .form-group {
      margin-bottom: 1rem;
      > span {
        font-size: 1.5rem;
        margin-left: 0.6rem;
        font-weight: 500;
      }
      > input {
        margin-top: 1rem;
        border: 1px solid #ccc;
        border-radius: 0.4rem;
        box-sizing: border-box;
        padding: 0.6rem;
        &:focus {
          border: 1px solid #80bdff;
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }
      }
    }
    .btn-confirm {
      float: right;
      margin-top: 1rem;
    }
  }
}
