.header {
  display: flex;
  background: rgb(70, 220, 150);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  transition: 0.2s linear;
  box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.2);
  .logo {
    height: 5rem;
    width: 28rem;
    > img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  .wrapper {
    height: 5rem;
    max-width: var(--l-width);
    margin: 0 2rem;
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
  }
  .action {
    .navbar {
      display: flex;
      > li {
        margin-right: 8rem;
        > a {
          color: #fff;
          text-align: center;
          &:hover {
            color: orange;
          }
        }
      }
    }
  }
}
