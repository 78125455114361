.wrapper {
  margin: 0 auto;
  .title {
    font-size: 4rem;
    padding: 3rem 0;
    border-bottom: 1px solid #ccc;
  }
  .warning {
    border: 1px solid #f5c6cb;
    border-radius: 4px;
    padding: 1rem 2rem;
    line-height: 2.4rem;
    color: #721c24;
    background: #f8d7da;
    font-size: 1.5rem;
    font-weight: 400;
    margin: 2rem 0;
  }

  .payment-info {
    padding: 3rem 0;
    .title-news {
      font-size: 3rem;
    }
  }
  .news-options {
    display: flex;
    .option-item {
      margin-right: 4rem;
      > p {
        padding-bottom: 1rem;
      }
    }
  }
  .total-price {
    margin-top: 4rem;
    > h1 {
      font-size: 2rem;
      > b {
        font-size: 2.4rem;
        padding-left: 1rem;
        color: red;
      }
    }
    > p {
      margin-top: 1rem;
      font-size: 1.5rem;
    }
  }
  .main {
    display: flex;
    align-items: center;
    .payment-options {
      min-width: 80rem;
      padding: 2rem 0;
      h1 {
        font-size: 2rem;
        padding: 2rem 0;
      }
      .payment-options-item {
        > p > span {
          color: red;
        }
        > span {
          color: red;
          font-size: 1.4rem;
          > a {
            color: #057a3a;
            font-weight: 600;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        > img {
          width: 6rem;
          height: 6rem;
        }
      }
      .action {
        margin-top: 4rem;
        display: flex;
        .btn {
          margin-right: 2rem;
          width: 50%;
        }
      }
    }
    .note {
      width: 47.6rem;
      height: fit-content;
      list-style: decimal;
      color: #856404;
      background: #fff3cd;
      padding: 2rem;
      border-radius: 4px;
      border: 1px solid #f6d46d;
      > h1 {
        padding-bottom: 2rem;
      }
      > li {
        padding-left: 0.4rem;
        margin: 0 0 1rem 2rem;
        font-size: 1.4rem;
        line-height: 2rem;
      }
    }
  }
}
