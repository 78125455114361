.background {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  > img {
    width: 100%;
    height: 10rem;
    border-end-start-radius: 10rem;
    border-top-right-radius: 0.6rem;
  }
}

.wrapper {
  overflow: hidden;
  padding: 10rem 2rem;
  .header {
    position: relative;
    margin-bottom: 4rem;
    .title {
      font-size: 3rem;
      font-weight: 600;
      color: #000;
    }
    .please {
      font-weight: 500;
      color: #7d7d7d;
    }
    .error-info {
      position: absolute;
      color: rgb(255, 65, 65);
      text-shadow: rgb(255, 46, 46) 1px 0 10px;
      bottom: -4rem;
      width: 100%;
      text-align: center;
    }
  }

  .btn {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding-top: 1rem;
    .btn-login {
      font-size: 2rem;
      padding: 2.8rem 2.5rem;
    }
  }
}
.register {
  text-align: center;
  .forgot {
    font-weight: 500;
    cursor: pointer;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
