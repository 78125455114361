.form-group {
  width: 100%;
  height: 6rem;
  position: relative;
  display: flex;
  align-items: flex-end;
  margin-bottom: 4rem;
  border-bottom: 1px solid #ccc;
  transition: 0.2s linear;

  &:focus-within {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 1rem;
  }

  > input {
    height: 100%;
    padding: 1rem 0 0 5rem;
    font-weight: 600;
    border-radius: 1rem;
    border: none;
    &:focus {
      + label {
        top: 0;
        font-size: 1.3rem;
        color: #6b6b6b;
      }
    }
    &:not(:placeholder-shown) {
      + label {
        top: 0;
        font-size: 1.3rem;
        color: #6b6b6b;
      }
    }
  }
  > input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
  .icon {
    position: absolute;
    left: 1rem;
    top: 2rem;
    > svg {
      font-size: 2.4rem;
    }
  }
  > label {
    position: absolute;
    left: 5rem;
    top: 2rem;
    color: #cbcbcb;
    font-weight: 500;
    pointer-events: none;
    transition: 0.05s linear;
  }
  .error-message {
    position: absolute;
    font-size: 1.4rem;
    color: rgb(255, 65, 65);
    text-shadow: rgb(255, 46, 46) 1px 0 10px;
    bottom: -2.6rem;
  }
  .show-pass {
    position: absolute;
    right: 1rem;
    top: 2rem;
    > span > svg {
      font-size: 2.4rem;
      cursor: pointer;
    }
  }
}
