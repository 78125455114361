.NewsRent {
  border: 1px solid #ccc;
  margin: 3rem 0;
  border-radius: 4px;
  padding: 2rem 1.4rem;
  .title {
    font-size: 2rem;
    padding-bottom: 2rem;
  }
  .news {
    display: flex;
    > img {
      height: 6.5rem;
      width: 6.5rem;
      cursor: pointer;
    }
    .news-post {
      margin-left: 1.5rem;
      > a {
        color: #20710a;
        font-weight: 500;
      }
      .news-info {
        display: flex;
        justify-content: space-between;
        padding-top: 1.4rem;
        .news-price {
          color: #0d59bc;
          font-weight: 800;
          font-size: 1.4rem;
        }
        .news-time {
          color: #626262;
          font-size: 1.4rem;
        }
      }
    }
  }
}
