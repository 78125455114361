.wrapper {
  position: relative;
  width: fit-content;
  height: 3rem;
  padding: 2.2rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  transition: 0.4s;
  font-weight: 600;
  overflow: hidden;
  border: 2px solid transparent;

  z-index: 1;
  cursor: pointer;
  > svg {
    padding-left: 0.6rem;
    font-size: 3rem;
  }
}

.primary {
  background: linear-gradient(
    90deg,
    rgb(128, 250, 156) 16%,
    rgba(144, 249, 167, 1) 21%,
    rgb(48, 245, 31) 100%
  );
  color: #1c1c1c;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 0;
    height: 100%;
    z-index: -1;
    transition: 0.6s;
  }

  &:hover::before {
    width: 100%;
    background: #ebffe1;
  }
}

.outline {
  background: rgb(213, 254, 223) 16%;
  border: 2px solid rgb(36, 251, 83);
  color: rgb(9, 77, 9);

  &:hover {
    color: #1c1c1c;
  }
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 0%;
    height: 100%;
    z-index: -1;
    transition: 0.6s;
  }
  &:hover::before {
    width: 100%;
    background: rgb(36, 251, 83);
  }
}

.disible {
  background: rgb(213, 254, 223) 16%;
  border: 2px solid rgb(36, 251, 83);
  color: rgb(9, 77, 9);
  opacity: 0.4;
  cursor: not-allowed;
}

.primarydisible {
  background: linear-gradient(
    90deg,
    rgb(128, 250, 156) 16%,
    rgba(144, 249, 167, 1) 21%,
    rgb(48, 245, 31) 100%
  );
  color: #1c1c1c;
  opacity: 0.4;
  cursor: not-allowed;
}
.dark {
  background: #6c757d;
  border: 2px solid #6c757d;
  color: #fff;

  &:hover {
    color: #1c1c1c;
  }
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 0%;
    height: 100%;
    z-index: -1;
    transition: 0.6s;
  }
  &:hover::before {
    width: 100%;
    background: #d5d5d5;
  }
}
