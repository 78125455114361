.wrapper {
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0 0rem 0;
    > h1 {
      font-size: 3rem;
    }
    .filter {
      display: flex;
      .filter-item {
        margin-right: 1rem;
        width: 18rem;
        height: 3rem;
        > div > span {
          font-size: 1.4rem;
        }
      }
    }
  }
  .pagination {
    display: flex;
    justify-content: flex-end;
    margin: 2rem 0;
    li {
      font-size: 1.5rem;
      > a,
      > div {
        font-size: 1.5rem;
      }
    }
  }

  > table,
  th,
  td {
    border: 1px solid #ccc;
    border-collapse: collapse;
  }
  > table {
    width: 100%;
    > tbody > tr > th {
      padding: 1rem;
    }
    .post-id {
      padding: 1rem;
    }
    .post-image {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 22rem;
      height: 20rem;
      padding: 1rem;
      > img {
        width: 90%;
        height: 80%;
        border-radius: 0.6rem;
      }
    }
    .post-info {
      padding: 1rem;
      .title {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        > b {
          margin-right: 0.4rem;
          color: #000;
        }
        .post-title {
          font-weight: 700;
          color: #3fb31b;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .info {
        margin-bottom: 1rem;
      }
      .btn-wrapper {
        margin-bottom: 1rem;
        display: flex;
        .btn {
          display: flex;
          border-radius: 0.4rem;
          align-items: center;
          background-color: #ccc;
          padding: 0.4rem;
          margin-right: 0.8rem;
          font-size: 1.4rem;
          font-weight: 600;
          transition: 0.2s;
          cursor: pointer;
          > svg {
            margin-right: 0.2rem;
          }
          &:hover {
            text-decoration: underline;
            opacity: 0.8;
          }
        }

        .btn-edit {
          background: #f1fdca;
          color: rgb(59, 73, 12);
        }
        .btn-delete {
          background: #ffddd9;
          color: red;
        }
        .btn-hide {
          background: #c2c2c2;
        }
        .btn-show {
          background: #d5fdca;
          color: rgb(12, 105, 15);
        }
        .btn-extend {
          background: #d3eeff;
          color: rgb(5, 52, 105);
        }
      }
      .update {
        display: flex;
        > p {
          font-size: 1.4rem;
          color: #a8a7a7;
          &:first-child {
            margin-right: 0.6rem;
          }
        }
      }
    }
    .totalNews {
      display: block;
      padding: 1rem;
      > p {
        color: #3d8c27;
        font-weight: 600;
        cursor: pointer;
        display: flex;
        align-items: center;
      }
    }
    .created {
      padding: 1rem;
      > p {
        color: #000;
      }
    }
    .role {
      padding: 1rem;
      display: flex;
      align-items: center;
      .btn-edit {
        cursor: pointer;
        background: #ffffca;
        margin-left: 1rem;
        border-radius: 4px;
        padding: 0.4rem;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        > svg {
          color: rgb(102, 102, 36);
        }
      }
    }

    .status {
      padding: 1rem;
      display: flex;
      align-items: center;
      .btn-edit {
        cursor: pointer;
        background: #ffffca;
        margin-left: 1rem;
        border-radius: 4px;
        padding: 0.4rem;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        > svg {
          color: rgb(102, 102, 36);
        }
      }
    }
  }
  .null {
    padding: 1rem;
    width: 100%;
    border: 1px solid #ccc;
    > a {
      color: #2a7117;
      cursor: pointer;
      font-weight: 700;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.menu-status {
  h1 {
    margin-bottom: 1rem;
    font-size: 2rem;
  }
  .list-option {
    .warn {
      color: #c09807;
      font-weight: 600;
    }
    .success {
      color: #3fb31b;
      font-weight: 600;
    }
    .error {
      color: #fb3a3a;
      font-weight: 600;
    }
    .hide {
      font-weight: 600;
    }
  }
  .container {
    .form-group {
      margin-bottom: 1rem;
      > span {
        font-size: 1.5rem;
        margin-left: 0.6rem;
        font-weight: 500;
      }
    }
    .form-action {
      display: flex;
      justify-content: space-between;
      .avatar {
        > div {
          display: flex;
          flex-direction: column;
          > img {
            width: 10rem;
            height: 10rem;
            border-radius: 50%;
          }
        }
      }
    }
  }
  .btn-submit {
    margin-top: 6rem;
    width: 100%;
  }
}
