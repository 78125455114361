.wrapper {
  > h1 {
    font-size: 3rem;
    margin-top: 1rem;
  }
  .stat-user,
  .stat-cate-post,
  .stat-room {
    margin-top: 2rem;
    > h2 {
        margin-bottom: 1rem;
    }
    > table,
    th,
    td {
      border: 1px solid #ccc;
      border-collapse: collapse;
    }
    > table {
      width: 90%;
      margin-right: 1rem;
      > tr {
        height: 4rem;
      }
    }
  }
}
