.sidebar {
  position: fixed;
  top: 5rem;
  bottom: 0;
  left: 0;
  z-index: 900;
  width: 28rem;
  padding: 2rem;
  border-right: 1px solid #e6e6e6;
  background-color: white;
  overflow-y: auto;
  .user {
    background-color: #eeebeb;
    border-radius: 1rem;
    padding: 1rem;
    .user-info {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      > img {
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
      }
      .user-details {
        margin-left: 1rem;
        > h3 {
          display: flex;
          align-items: center;
          > svg {
            margin-left: 0.4rem;
            color: #009c05;
          }
        }
        > p {
          margin-top: 0.6rem;
          font-size: 1.4rem;
          font-weight: 300;
        }
      }
    }
    .user-id {
      display: flex;
      > p {
        font-size: 1.4rem;
        &:nth-child(2) {
          margin-left: 1rem;
          font-weight: 700;
        }
      }
    }
    .user-balance {
      display: flex;
      > p {
        font-size: 1.4rem;
        &:nth-child(2) {
          margin-left: 1rem;
          font-weight: 700;
        }
      }
    }
  }
  .btn {
    margin-top: 2rem;
    margin-bottom: 3rem;
    > a {
      border-radius: 0.4rem;
      margin-right: 1rem;
      padding: 0.6rem;
      font-size: 1.4rem;
      &:first-child {
        color: black;
        background-color: #ffc107;
        &:hover {
          background-color: #e0a800;
        }
        &:focus {
          box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
        }
      }
      &:last-child {
        color: white;
        background-color: #dc3545;
        &:hover {
          background-color: #c82333;
        }
        &:focus {
          box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
        }
      }
    }
  }
  > p {
    margin-bottom: 1rem;
    font-weight: 600;
    color: #ccc;
  }
  .nav-sidebar {
    margin-bottom: 1rem;
    .nav-item {
      display: flex;
      align-items: center;
      padding: 1rem 1rem;
      margin-bottom: 0.6rem;
      transition: 0.2s;
      cursor: pointer;
      &:hover {
        transform: translateX(8px);
        background-color: #ecf9f0;
        border-radius: 1rem;
        > span {
          color: #68cc97;
        }
      }
      > svg {
        color: #666666;
        font-size: 2rem;
        margin-right: 1rem;
      }
      > span {
        color: black;
      }
    }
    .active {
      margin-left: 0.8rem;
      background-color: #ecf9f0;
      border-radius: 1rem;
      > span {
        color: #68cc97;
        font-weight: 600;
      }
      > svg {
        color: #68cc97;
      }
      &:hover {
        transform: none;
      }
    }
    .logout {
      color: red;
      > svg {
        color: red;
      }
      &:hover {
        background: rgb(255, 217, 217);
        color: red;
      }
    }
  }
}
