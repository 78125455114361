.wrapper {
  > h1 {
    font-size: 3rem;
    margin-top: 2rem;
  }
  .rate-select {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    > h2 {
      margin-top: 0.8rem;
      margin-right: 1rem;
    }
  }
  .rate-title {
    margin-top: 1rem;
    > input {
      margin-top: 1rem;
      border: 1px solid #ccc;
      border-radius: 0.4rem;
      box-sizing: border-box;
      padding: 0.6rem;
      &:focus {
        border: 1px solid #80bdff;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      }
    }
  }
  .rate-content {
    margin-top: 1rem;
    .content {
      margin-top: 1rem;
      width: 100%;
      height: 25rem;
      border-radius: 0.8rem;
      padding: 0.6rem;
      border: 1px solid #ccc;
      resize: vertical;
      outline: none;
      &:focus {
        border: 1px solid #80bdff;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      }
    }
  }
  .btn-submit {
    margin-top: 1rem;
    float: right;
  }
}
