.wrapper {
  > h1 {
    font-size: 3rem;
    margin-top: 1rem;
  }
  > p {
    margin-top: 1rem;
    color: #a0a0a0;
  }
  .username {
    margin-top: 1rem;
    > p {
      font-weight: 700;
    }
    > input {
      margin-top: 1rem;
      width: 40%;
      height: 4rem;
      border: 1px solid #ccc;
      border-radius: 0.8rem;
      padding: 0.6rem;
      &:focus {
        border: 1px solid #80bdff;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      }
    }
  }
  .email {
    margin-top: 1rem;
    > p {
      font-weight: 700;
    }
    > input {
      margin-top: 1rem;
      width: 40%;
      height: 4rem;
      border: 1px solid #ccc;
      border-radius: 0.8rem;
      padding: 0.6rem;
      &:focus {
        border: 1px solid #80bdff;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      }
    }
  }
  .message {
    margin-top: 1rem;
    > p {
      font-weight: 700;
    }
    > textarea {
      margin-top: 1rem;
      width: 100%;
      height: 25rem;
      border-radius: 0.8rem;
      padding: 0.6rem;
      border: 1px solid #ccc;
      resize: vertical;
      outline: none;
      &:focus {
        border: 1px solid #80bdff;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      }
    }
  }
  .btn-send {
    float: right;
    width: 15rem;
    margin-top: 1rem;
  }
}
