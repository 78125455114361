.loader-container {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  background: rgba(231, 231, 231, 0.6)
    url("../../Image/35771931234507.564a1d2403b3a\ \(1\).gif") center no-repeat;
  z-index: 99999;
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
