.house-details {
  max-width: var(--l-width);
  margin: 10rem auto 0 auto;
  .left-content {
    border: 1px solid #ccc;
    .post-image {
      background-color: black;
      img {
        width: 100%;
        height: 30rem;
        object-fit: contain;
      }
      .btn-slide {
        position: absolute;
        background: #f3f3f3;
        transform: translateY(-50%);
        opacity: 0.2;
        padding: 2rem 0.6rem;
        &:hover {
          opacity: 0.8;
        }
      }
      .btn-prev {
        left: 0;
        top: 50%;
      }
      .btn-next {
        right: 0;
        top: 50%;
      }
    }
    .post-header {
      margin: 2rem 2rem;
      .title {
        display: flex;
        align-items: center;

        > h1 {
          font-size: 2rem;
          font-weight: 700;
          cursor: pointer;
          margin-right: 16rem;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          &:hover {
            text-decoration: underline;
          }
        }
        > svg {
          font-size: 2rem;
          color: #d8e300;
        }
        > small {
          position: absolute;
          right: 2rem;
          font-size: 1.4rem;
          display: flex;
          align-items: center;
          color: #767676;
          > svg,
          b {
            margin-right: 0.4rem;
          }
        }
      }
      > p {
        margin: 1rem 0;
        > a {
          text-decoration: underline;
          font-weight: 500;
          color: #1266dd;
          &:hover {
            color: #7dcf66;
          }
        }
      }
      .post-address {
        display: flex;
        align-items: center;
        margin: 1rem 0;
        > svg {
          color: red;
          margin-right: 0.5rem;
        }
      }
      .post-attributes {
        display: flex;
        align-items: center;
        .post-price {
          display: flex;
          align-items: center;
          > svg {
            color: rgb(165, 166, 163);
            margin-right: 0.5rem;
          }
          > p {
            color: #0d59bc;
            font-weight: 800;
            font-size: 2rem;
            margin-right: 3rem;
          }
        }
        .post-acreage {
          display: flex;
          align-items: center;
          > svg {
            color: rgb(165, 166, 163);
            margin-right: 0.5rem;
          }
          > p {
            margin-right: 3rem;
          }
        }
        .post-published {
          display: flex;
          align-items: center;
          > svg {
            color: rgb(165, 166, 163);
            margin-right: 0.5rem;
          }
          > p {
            margin-right: 3rem;
          }
        }
        .post-code {
          display: flex;
          align-items: center;
          > svg {
            color: rgb(165, 166, 163);
            margin-right: 0.5rem;
          }
          > p {
            margin-right: 3rem;
          }
        }
      }
    }
    .post-description {
      margin: 2rem 2rem;
      > h2 {
        font-weight: 700;
        font-size: 2rem;
      }
      > p {
        margin: 2rem 0;
      }
    }
    .post-overview {
      margin: 2rem 2rem;
      > h2 {
        font-weight: 700;
        font-size: 2rem;
      }
      > table {
        margin: 2rem 0;
        width: 100%;
        border-spacing: 0;
        tbody {
          > tr {
            > td {
              padding: 1rem 0;
              &:nth-child(1) {
                width: 25%;
              }
            }
            &:nth-of-type(odd) {
              background-color: #e7e7e7;
            }
            &:nth-of-type(even) {
              background-color: #abdae7;
            }
          }
        }
      }
    }
    .post-contact {
      margin: 2rem 2rem;
      > h2 {
        font-weight: 700;
        font-size: 2rem;
      }
      > table {
        margin: 2rem 0;
        width: 100%;
        border-spacing: 0;
        tbody {
          > tr {
            > td {
              padding: 1rem 0;
              &:nth-child(1) {
                width: 25%;
              }
            }
            &:nth-of-type(odd) {
              background-color: #e7e7e7;
            }
            &:nth-of-type(even) {
              background-color: #abdae7;
            }
          }
        }
      }
    }
    .post-map {
      margin: 2rem 2rem;
      > h2 {
        font-weight: 700;
        font-size: 2rem;
      }
      > p {
        margin: 1rem 0;
      }
    }
  }
  .action-detail {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #d5fdca;
    padding: 2rem;
    border-radius: 0.8rem;
    .avatar {
      width: 8rem;
      height: 8rem;
      border-radius: 50%;
      overflow: hidden;
      margin-bottom: 1.6rem;
      > img {
        width: 100%;
        height: 100%;
      }
    }
    .name {
      display: flex;
      align-items: center;
      font-size: 2.2rem;
      padding-bottom: 1rem;
      > svg {
        margin-left: 0.4rem;
        color: #009c05;
      }
    }
    .status {
      display: flex;
      align-items: center;
      font-size: 1.4rem;
      > svg {
        color: green;
        transform: translateY(0.1rem);
        margin-right: 0.4rem;
      }
    }
    .action {
      display: flex;
      margin-top: 2rem;
      justify-content: space-between;
      width: 100%;
      .action-left {
        width: 50%;
        .btn {
          display: flex;
          justify-content: center;
          width: 100%;
          border-radius: 1rem;
          > svg {
            margin-right: 0.4rem;
          }
          &:first-child {
            margin-bottom: 1rem;
          }
        }
      }
      .action-right {
        .btn {
          width: 100%;
          border-radius: 1rem;
          > svg {
            margin-right: 0.4rem;
          }
          &:first-child {
            margin-bottom: 1rem;
          }
        }
      }
    }
    .action-call {
      width: 100%;
      .btn {
        display: flex;
        justify-content: center;
        width: 100%;
        border-radius: 1rem;
      }
    }
  }
  .care {
    width: 100% !important;
  }
}
