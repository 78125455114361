.wrapper {
  max-width: var(--l-width);
  margin: -4rem auto 0 auto;
  .bread-item {
    display: flex;
    align-items: flex-start;
  }
  .title {
    padding: 2rem 0;
    font-size: 3rem;
  }
  .sub-title {
    color: #626262;
  }
  .suggets-address {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #ccc;
    margin: 3rem 0;
    border-radius: 4px;
    padding: 2rem 1.4rem;
    color: #414141;
    .suggest-item {
      padding: 1rem;
      margin-right: 2rem;
      font-weight: 600;
      cursor: pointer;
      > small {
        font-size: 1.4rem;
        color: #666666;
        font-weight: 400;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .list-news {
    border: 1px solid #ccc;
    margin: 3rem 0;
    border-radius: 4px;
    padding: 2rem 1.4rem;
    .list-news-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > h1 {
        font-size: 2rem;
      }
      .update-at {
        font-size: 1.5rem;
      }
    }
    .sort {
      padding: 2rem 0;
      border-bottom: 2px solid #1bfb1b;
      .sort-item {
        background: #fff;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 0.6rem 1rem;
        font-size: 1.4rem;
        margin-right: 1rem;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
      .active {
        text-decoration: underline;
        font-weight: 600;
      }
    }
    .not-found {
      margin-top: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      > img {
        height: 30rem;
      }
      > h1 {
        font-size: 3rem;
        margin-top: 1rem;
      }
    }

    .news {
      padding: 2rem 0;
      display: flex;
      border-bottom: 1px solid #ccc;
      cursor: pointer;
      &:last-child {
        border: none;
      }
      &:hover .main-image > img {
        transform: scale(1.2);
      }
      &:hover .title-news {
        text-decoration: underline;
      }
      .main-image {
        position: relative;
        width: 26rem;
        height: 20rem;
        margin-right: 1rem;
        overflow: hidden;
        border-radius: 4px;

        > img {
          width: 100%;
          height: 100%;
          object-fit: fill;
          transition: 0.2s ease-in;
        }
        .image-count {
          position: absolute;
          background: rgba(0, 0, 0, 0.5);
          color: #fff;
          font-size: 1.4rem;
          padding: 0.4rem;
          border-radius: 4px;
          left: 1rem;
          bottom: 1.2rem;
        }
        .love-icon {
          cursor: pointer;
          position: absolute;
          right: 1rem;
          bottom: 0.6rem;

          > svg {
            font-size: 2.6rem;
            color: #5f5f5f;
            &:hover {
              color: rgb(255, 70, 70);
            }
          }
        }
      }
      .news-info {
        flex-basis: 100%;

        .title-news {
          font-size: 1.5rem;
          color: #20710a;
          padding-right: 2rem;
          line-height: 2.4rem;
        }
        .info {
          padding: 2rem 0;
          display: flex;
          justify-content: space-between;
          .info-item {
            font-size: 1.4rem;
          }
          .price {
            font-size: 1.6rem;
            font-weight: 800;
            color: #0d59bc;
          }
          .updated {
            color: #5f5f5f;
          }
        }
        .info-description {
          font-size: 1.4rem;
          color: #626262;
          line-height: 2.4rem;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
      }
      .actor {
        display: flex;
        margin-top: 1rem;
        align-items: flex-end;
        .actor-image {
          width: 4rem;
          height: 4rem;
          > img {
            width: 100%;
            height: 100%;
            object-fit: fill;
            border-radius: 50%;
          }
        }
        .actor-name {
          padding: 0 0 0.4rem 1rem;
          font-weight: 500;
        }
      }
    }
  }
  .related {
    width: 100% !important;
    > span {
      line-height: 2.2rem;
      > svg {
        margin-right: 0.6rem;
      }
    }
  }

  .pagination {
    margin: 4rem;
    display: flex;
    justify-content: center;
  }
}
