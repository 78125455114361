.header {
  background: transparent;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  transition: 0.2s linear;
  .wrapper {
    height: 10rem;
    max-width: var(--l-width);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    position: relative;

    .logo {
      width: 16rem;
      height: 100%;

      > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .action {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .action-item {
        position: relative;
        display: flex;
        align-items: center;
        padding: 0.4rem 1.6rem;
        margin: 0 1rem;
        border: 0.4px solid #6b6b6b;
        border-radius: 30px;
        color: #6b6b6b;
        font-weight: 500;
        transition: 0.4s;
        .count-newsFavorite {
          position: absolute;
          right: 0;
          top: -0.6rem;
          background: rgb(248, 70, 70);
          color: #fff;
          padding: 0.4rem 0.8rem;
          border-radius: 50%;
          font-size: 1.4rem;
          font-weight: 700;
        }
        > svg {
          padding-left: 0.6rem;
          font-size: 3rem;
        }

        &:hover {
          background: #d0ffdb;
          cursor: pointer;
          color: #313131;
        }
        &:first-child:hover > svg {
          color: red;
        }
      }
      .select {
        background: #d0ffdb;
      }
      .btn-news {
        height: 4rem;
        margin: 0 1rem;
        > svg {
          font-size: 3rem;
        }
      }
      .user {
        .login {
          font-weight: 700;
        }
        .avatar {
          .image {
            width: 6rem;
            height: 6rem;
            cursor: pointer;
            > img {
              box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
                rgba(0, 0, 0, 0.23) 0px 6px 6px;
              border: 3px solid #b1fcc3;
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 50%;
            }
          }
        }
      }
      .more {
        cursor: pointer;
        > svg {
          padding: 0;
          font-size: 4rem;
          margin: 0;
          transform: translateY(0.2rem);
        }
      }
    }
  }
}
.popover-item {
  > li {
    display: flex;
    align-items: center;
    padding: 1rem 0;
    cursor: pointer;
    border-radius: 4px;
    &:last-child {
      &:hover {
        background: none;
      }
    }
    &:hover {
      background: #ededed;
    }
    > svg {
      font-size: 2.6rem;
      margin-right: 1rem;
    }
    > button {
      margin-left: 1rem;
    }
  }
}

.menu {
  min-width: 20rem;
  padding: 0 1rem;
  .title {
    display: flex;
    align-items: center;
    > span {
      margin-left: 0.4rem;
      font-weight: 600;
    }
    > svg {
      margin-left: 0.4rem;
      color: #009c05;
    }
  }
  .amount {
    display: flex;
    align-items: center;
    > span {
      margin-left: 0.4rem;
      color: #08571b;
      font-weight: 600;
    }
  }
  .menu-item {
    cursor: pointer;
    padding: 1rem 0 0.4rem 0;
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;
    border-radius: 4px;
    color: #313131;
    > span {
      margin-left: 1rem;
    }
    > svg {
      font-size: 2rem;
    }

    &:last-child {
      color: red;
      margin-top: 2rem;
      border: none;
      &:hover {
        color: red;
      }
    }
    &:hover {
      text-decoration: underline;
      color: #00b42a;
    }
  }
}
