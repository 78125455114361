.background {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  > img {
    width: 100%;
    height: 10rem;
    border-end-start-radius: 10rem;
    border-top-right-radius: 0.6rem;
  }
}

.wrapper {
  padding: 4rem 2rem;
  .title {
    z-index: 10;
    font-size: 3rem;
    font-weight: 600;
    margin-bottom: 2rem;
  }
  .code {
    > input {
      padding-right: 10rem;
    }
    .btn-code {
      position: absolute;
      right: 0;
      bottom: 0;
      height: 100%;
      border-radius: 8px;
    }
  }

  .btn {
    display: flex;
    justify-content: right;
    .btn-register {
      font-size: 2rem;
      padding: 2.8rem 2.5rem;
      margin-top: 1rem;

      .loading {
        animation: spin 0.4s infinite linear;
      }
    }
  }
}
.register {
  text-align: center;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
