.news {
  padding: 2rem 0;
  display: flex;
  border-bottom: 1px solid #ccc;
  &:last-child {
    border: none;
  }
  .main-image {
    position: relative;
    width: 26rem;
    height: 20rem;
    margin-right: 1rem;
    overflow: hidden;
    cursor: pointer;
    border-radius: 4px;

    > img {
      width: 100%;
      height: 100%;
      object-fit: fill;
      transition: 0.2s ease-in;
      &:hover {
        transform: scale(1.2);
      }
    }
    .image-count {
      position: absolute;
      background: rgba(0, 0, 0, 0.5);
      color: #fff;
      font-size: 1.4rem;
      padding: 0.4rem;
      border-radius: 4px;
      left: 1rem;
      bottom: 1.2rem;
    }
    .tag-vip {
      width: 4rem;
      height: 4rem;
      position: absolute;
      border-radius: 4px;
      left: 0;
      > img {
        width: 100%;
        height: 100%;
      }
    }
    .unlove-icon {
      color: rgb(255, 58, 77);
      &:hover {
        color: rgb(255, 92, 92);
      }
    }
    .love-icon {
      color: #ff6767;
      &:hover {
        color: red;
      }
    }
    .icon {
      cursor: pointer;
      position: absolute;
      right: 1rem;
      bottom: 0.6rem;
      > svg {
        transition: 0.2s;
        font-size: 3rem;
        &:hover {
          transform: scale(1.3);
        }
      }
    }
  }
  .news-info {
    flex-basis: 100%;
    .title-news {
      display: flex;
      position: relative;
      align-items: center;
      > svg {
        color: #d8e300;
      }
      > b {
        width: 50rem;
        font-weight: 700;
        font-size: 1.5rem;
        color: #20710a;
        padding-right: 10rem;
        line-height: 2.4rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
      > span {
        font-weight: 700;
        font-size: 1.6rem;
        color: #030503;
        padding-right: 10rem;
        line-height: 2.4rem;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
      > small {
        position: absolute;
        right: 0;
        color: #8a8a8a;
        font-size: 1.4rem;
        display: flex;
        align-items: center;
        > svg {
          margin-right: 0.4rem;
        }
      }
    }
    .info {
      padding: 2rem 0;
      display: flex;
      justify-content: space-between;
      .info-item {
        font-size: 1.4rem;
      }
      .price {
        font-size: 1.6rem;
        font-weight: 800;
        color: #0d59bc;
      }
      .updated {
        color: #5f5f5f;
      }
    }
    .info-description {
      font-size: 1.4rem;
      color: #626262;
      line-height: 2.4rem;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .actor {
      display: flex;
      margin-top: 1rem;
      align-items: flex-end;
      .actor-image {
        width: 4rem;
        height: 4rem;
        > img {
          width: 100%;
          height: 100%;
          object-fit: fill;
          border-radius: 50%;
        }
      }
      .actor-name {
        display: flex;
        align-items: center;
        padding: 0 0 0.4rem 1rem;
        font-weight: 500;
        > svg {
          margin-left: 0.4rem;
          color: #009c05;
        }
      }
    }
    .btn {
      display: flex;
      > button {
        padding: 0.4rem 1.4rem;
        font-size: 1.4rem;
        border-radius: 8px;
        cursor: pointer;
        transition: 0.2s ease-in;
        border-width: 1px;
        overflow: hidden;

        &:last-child {
          margin-left: 2rem;
        }
      }
    }
  }
}
