.wrapper {
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0 0rem 0;
    > h1 {
      font-size: 3rem;
    }
    .filter {
      display: flex;
      .filter-item {
        margin-right: 1rem;
        width: 18rem;
        height: 3rem;
        > div > span {
          font-size: 1.4rem;
        }
        &:last-child {
          background: #dc3545;
          color: #fff;
          width: fit-content;
          font-size: 1.4rem;
          border-radius: 4px;
          line-height: 3rem;
          padding: 0 1rem;
          margin: 0;
          &:hover {
            background: #464b0f;
            background: #b82e3b;
          }
        }
      }
    }
  }
  .pagination {
    display: flex;
    justify-content: flex-end;
    margin: 2rem 0;
    li {
      font-size: 1.5rem;
      > a,
      > div {
        font-size: 1.5rem;
      }
    }
  }

  > table,
  th,
  td {
    border: 1px solid #ccc;
    border-collapse: collapse;
  }
  > table {
    width: 100%;
    > tbody > tr > td {
      padding: 1rem;
      position: relative;
    }
    .post-id {
      padding: 1rem;
    }
    .post-image {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 20rem;
      width: 100%;
      height: 20rem;
      padding: 1rem;
      > img {
        width: 80%;
        height: 80%;
        border-radius: 0.6rem;
      }
    }
    .post-info {
      padding: 1rem;
      .title {
        width: 50rem;
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        overflow: hidden;
        > p {
          font-size: 1rem;
          font-weight: 600;
          padding: 0.4rem;
          background-color: orange;
          border-radius: 1rem;
          margin-right: 0.4rem;
          color: #000;
        }
        .post-title {
          font-size: 1.4rem;
          font-weight: 700;
          color: #2c72b0;
          cursor: pointer;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          > small {
            font-size: 1.4rem;
            color: rgb(255, 65, 65);
            display: inline-flex;
            align-items: flex-end;
            position: absolute;
            top: 0.6rem;
            left: 2rem;
          }
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .address {
        margin-bottom: 1rem;
        padding-right: 2rem;
        font-size: 1.4rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }
      .actor {
        margin: -0.4rem 0 1rem 0;
        font-size: 1.4rem;
        color: #2a7117;
        font-weight: 600;
        > b {
          color: #000;
        }
      }
      .views {
        font-size: 1.4rem;
        margin-bottom: 0.6rem;
      }
      .btn-wrapper {
        margin-bottom: 1rem;
        display: flex;
        .btn {
          display: flex;
          border-radius: 0.4rem;
          align-items: center;
          background-color: #ccc;
          padding: 0.4rem;
          margin-right: 0.8rem;
          font-size: 1.4rem;
          font-weight: 600;
          transition: 0.2s;
          cursor: pointer;
          > svg {
            margin-right: 0.2rem;
          }
          &:hover {
            text-decoration: underline;
            opacity: 0.8;
          }
        }

        .btn-edit {
          background: #f1fdca;
          color: rgb(59, 73, 12);
        }
        .btn-delete {
          background: #ffddd9;
          color: red;
        }
        .btn-hide {
          background: #c2c2c2;
        }
        .btn-show {
          background: #d5fdca;
          color: rgb(12, 105, 15);
        }
        .btn-extend {
          background: #d3eeff;
          color: rgb(5, 52, 105);
        }
      }
      .update {
        display: flex;
        > p {
          font-size: 1.4rem;
          color: #a8a7a7;
          &:first-child {
            margin-right: 0.6rem;
          }
        }
      }
    }
    .price {
      padding: 1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 40rem;
      > p {
        color: #a0c297;
      }
    }
    .post-date {
      padding: 1rem;
      > p {
        color: #000;
      }
    }
    .expire-date {
      padding: 1rem;
      > p {
        color: #000;
      }
    }

    .status {
      padding: 1rem;
      display: flex;
      align-items: center;
      min-width: 20rem;
      .btn-edit {
        cursor: pointer;
        background: #ffffca;
        margin-left: 1rem;
        border-radius: 4px;
        padding: 0.4rem;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        > svg {
          color: rgb(102, 102, 36);
        }
      }
    }
  }
  .null {
    padding: 1rem;
    width: 100%;
    border: 1px solid #ccc;
    > a {
      color: #2a7117;
      cursor: pointer;
      font-weight: 700;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.menu-status {
  h1 {
    margin-bottom: 1rem;
    font-size: 2rem;
  }
  .list-option {
    .warn {
      color: #c09807;
      font-weight: 600;
    }
    .success {
      color: #3fb31b;
      font-weight: 600;
    }
    .error {
      color: #fb3a3a;
      font-weight: 600;
    }
    .hide {
      font-weight: 600;
    }
    .not-pay {
      color: #3aa1fb;
      font-weight: 600;
    }
  }
  .info-group {
    margin-bottom: 1rem;
    display: flex;
    align-items: flex-start;
    > b {
      flex: 0 0 11rem;
    }
    > span {
      font-size: 1.4rem;
    }
  }
  .btn-submit {
    margin-top: 6rem;
    width: 100%;
  }
}
